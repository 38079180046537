import React from "react"
import { Link } from "gatsby"
import Layout from "../templates/pageLayout"
import * as legalStyles from "./legalStyles.module.css"

export default function PrivacyPolicy() {
  return (
    <Layout title="Privacy Policy">
      <div className="container">
        <div>
          <h1>Privacy Policy</h1>
          <div className={legalStyles.mainContainer}>
            <div>
              <h3>1. TBC</h3>
              <p>TBC</p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
